import React from "react"
import { css } from "emotion"

function BasicContentSection({ children, href, imgRight, imgPosition }) {
  if(href){
  return imgRight ? (
    <div className={contentContainerImgRight}>
      <p className={content}>{children}</p>
      <div
        style={{
          backgroundImage: `url('${href}')`,
          backgroundPosition: imgPosition,
          margin: "auto"
        }}
        className={contentImg}
      />
    </div>
  ) : (
    <div className={contentContainer}>
      <div
        style={{
          backgroundImage: `url('${href}')`,
          backgroundPosition: imgPosition,
          margin: "auto"
        }}
        className={contentImg}
      />
      <p className={content}>{children}</p>
    </div>
  )
      }
      else{
        return (          
          <div className={contentContainer}>
            <p className={content}>{children}</p>           
          </div>
        )
      }

}

export default BasicContentSection

const contentContainer = css`
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;

  & > img {
    margin-right: 25px;

    & > img {
      margin-right: 0px;
    }
  }
  @media (max-width: 650px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
const contentContainerImgRight = css`
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;

  & > img {
    margin-left: 25px;
  }

  @media (max-width: 650px) {
    flex-wrap: wrap-reverse;
    justify-content: center;

    & > img {
      margin-left: 0px;
    }
  }
`

const contentImg = css`
  min-width: 200px;
  min-height: 200px;
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
`
const content = css`
  padding: 20px;
  min-width: 200px;
  margin-bottom:0px;  
`
