import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import MediaTile from "../components/MediaTile"
import Button from "../components/Button"
import BasicContentSection from "../components/BasicContentSection"
import { css } from "emotion"

// Image imports
import headerImg from "../images/2023-12/family-2.jpg"
import alexRangers from "../images/2022-02/alex-royal-rangers-1.jpg"
import alexTractor from "../images/2022-02/tractor.jpg"
import familyPic1 from "../images/2023-12/family-3.jpg"
import alexHospital from "../images/2022-02/alex-hospital.jpg"
import alexGrad from "../images/2022-02/alex-grad-1.jpg"
import alexGrad2 from "../images/2022-02/alex-grad-2.jpg"
import goDawgs from "../images/2022-02/go-dawgs.jpg"
import alexLaw from "../images/2022-02/alex-law.jpg"
import alexChiefs from "../images/2022-02/chiefs.jpg"

const About = () => (
  <Layout>
    <SEO title="About" keywords={[`about`, `Alex Riley`, `info`]} />
    <MediaTile
      backgroundImage={headerImg}
      backgroundColor="blue"
      color="white"
      backgroundPosition="50% 20%"
      backgroundOpacity=".1"     
      minHeight="500px" 
    >
    </MediaTile>
    <div style={{backgroundColor:"#0A273B", width:"100%", height:"auto"}}>
        <h1 className="hero-h1">                                                    
              <span className="hero-text hero-h1">About</span>          
          </h1>    
      </div>

    <BasicContentSection
      href={familyPic1}
      imgPosition="center"      
    >
      Alex, a conservative Republican, has served as the State Representative for Missouri's 134th District since January of 2021. 
      He lives in Springfield's Parkcrest neighborhood with his wife Ellen and their three young kids Mitchell, Sophie, and Carson. 
      Alex was born and raised in Springfield and has lived in the 134th District for over twenty years. 
      {/* Alex, a conservative Republican, has served as the State Representative for Missouri's 134th District since January of 2021. 
      He lives in Springfield's Parkcrest neighborhood with his high school sweetheart and wife of {yearsElapsed('2014-07-26') || '9'} years Ellen and their young kids. Alex was born and raised in Springfield and has lived in the 134th 
      District for over twenty years. Other than a short time away for law school, he has lived in Springfield his entire life. */}
    </BasicContentSection>
    <BasicContentSection imgRight href={alexTractor}>
      Alex's parents taught him the values of faith, family, patriotism, and
      hard work from the beginning. His dad, a registered nurse and former
      farmer, put in many hours of overtime to provide for the family and this
      work ethic made a lasting impression on Alex. At an early age, Alex began
      a small business mowing lawns to earn some extra money. He and his younger
      siblings were homeschooled from kindergarten all the way through high
      school by his mom, a homemaker and former nurse.
    </BasicContentSection>
    <BasicContentSection href={alexRangers} imgPosition="left">
      Growing up, Alex was active in Royal Rangers, a faith-based program
      similar to Boy Scouts. He earned the Royal Ranger Gold Medal of
      Achievement, the highest award achievable and, in 2008, was named the
      National Royal Ranger of the Year. He ultimately graduated as one of the
      most decorated Rangers in the history of the program.
    </BasicContentSection>

    <BasicContentSection imgRight href={alexHospital}>
      Alex worked hard in high school, both in his studies and in his
      extracurricular activities, in pursuit of his ultimate goal of attending
      the United States Naval Academy. However, in the spring of 2009, a few
      months after his 17th birthday, he was diagnosed with Hodgkin’s lymphoma -
      a form of cancer. Because of this diagnosis, he could not meet the Naval
      Academy's physical requirements for admission. Though this diagnosis ended
      his Academy aspirations, Alex knew God had other plans for him. After
      nearly a year of chemotherapy and radiation treatments, he learned he was
      cancer free shortly before his 18th birthday. This experience only
      reinforced Alex’s appreciation for life and his belief that all life is
      sacred.
    </BasicContentSection>
    <BasicContentSection href={alexGrad}>
      While Alex's cancer diagnosis changed his college and career plans, he did
      not let that deter him. Instead, he began looking for an alternative to
      traditional four-year universities. He had concerns over the cost and debt
      load so many students experience and was interested in an expedited
      process so he could enter the workforce faster. To accomplish this, Alex
      began testing out of college courses during his senior year of high
      school. Within a year, he had tested out of three quarters of the
      requirements. He finished his remaining courses through Thomas Edison
      State University and graduated at age 19 with a B.A. in Political Science
      and a 4.0 GPA. He worked his way through college and achieved his goal of
      graduating with no student loan debt.
    </BasicContentSection>
    <BasicContentSection imgRight href={goDawgs} imgPosition="center right">
      Following college, Alex took a job as a paralegal at a local law firm. It
      was there he developed a passion for defending businesses and medical
      providers in our court system. Some attorneys at the firm encouraged Alex
      to go to law school. Ellen supported the idea and encouraged him to go
      too. So he took the LSAT, received multiple scholarship offers, and
      accepted the best one from Southern Illinois University School of Law in
      Carbondale, Illinois.
    </BasicContentSection>
    <BasicContentSection href={alexGrad2}>
      Alex made the most of his time in law school where he was a strong
      advocate for conservative principles. He served as the Chief Articles
      Editor of the Southern Illinois University Law Journal and wrote and
      published two articles advocating for free speech and religious liberties.
      He was also a teaching assistant, a research assistant, and he worked in
      the school’s Elder Law Clinic providing free legal assistance to seniors.
      Alex received multiple awards while in law school and graduated in 2016,
      fourth in his class, Summa Cum Laude.
    </BasicContentSection>
    <BasicContentSection imgRight href={alexLaw} imgPosition="center">
      The summer after graduating, Alex passed the Missouri Bar Exam. He then
      came back home to Springfield and took a job as an attorney with a local
      law firm defending businesses, medical providers, and individuals when
      they were sued. Alex is currently an attorney with the Healy Law Offices in
      Springfield and has successfully defended businesses of all shapes and
      sizes - from Fortune 500 companies to small local businesses - in
      courtrooms across Missouri.
    </BasicContentSection>
    <BasicContentSection href={alexChiefs} imgPosition="center">
      Alex and his family attend Connect Church in Springfield. During their free time,
      they enjoy camping, exploring the Ozarks countryside, and cheering on the
      Kansas City Chiefs.
    </BasicContentSection>
    <MediaTile backgroundColor="white" color="#0A273B">
      <h1>Support Alex</h1>
      <div>
        <Button href="/donate">Donate</Button>
      </div>
    </MediaTile>
  </Layout>
)

export default About

const yearsElapsed = (since) => {
  try {
    const d1 = new Date(since);
    const d2 = new Date();
    const elapsed = d2.getFullYear() - d1.getFullYear();
    return d2.getMonth() > d1.getMonth() || (d1.getMonth() === d2.getMonth() && d2.getDate() >= d1.getDate())
      ? elapsed
      : elapsed - 1;
  }
  catch {
    return undefined;
  }
}
